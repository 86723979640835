import React from "react";
import cn from "classnames";
import Button, { Variant } from "storefront/components/Button";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import BackgroundImage from "storefront/components/Image/BackgroundImage";
import { FullWidthCTAModule } from "storefront/Contentful/types";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./FullWidthCTA.module.scss";

type Props = {
  entry: FullWidthCTAModule;
  from: string;
  position: number;
};

const FullWidthCTA = ({ entry, from, position }: Props) => {
  const { track } = useAnalytics();

  const trackClick = (_event: React.MouseEvent<HTMLAnchorElement>) => {
    const { ctaText, desktopImage, name, title } = entry.fields;
    track(
      moduleClicked({
        from,
        itemName: ctaText,
        itemNameContentful: ctaText,
        itemPosition: "CTA",
        itemType: "CTA",
        imageUrl: desktopImage.fields.file.url,
        moduleName: title,
        moduleNameContentful: name,
        modulePosition: position,
        moduleType: "Full Width CTA",
      }),
    );
  };

  const {
    ctaColor,
    ctaLink,
    ctaText,
    desktopImage,
    height,
    mobileImage,
    textAlignment,
    title,
  } = entry.fields;

  const ctaClass = cn(styles.cta, {
    [styles.strong]: ctaColor === "black",
  });

  let buttonVariant: Variant = "secondary";
  switch (ctaColor) {
    case "black":
      buttonVariant = "primary";
      break;

    case "blue":
      buttonVariant = "important";
      break;

    default:
      break;
  }

  return (
    <BackgroundImage
      TagName="div"
      className={cn(styles.fullWidthCTA, styles[height])}
      sources={{
        desktop: { url: desktopImage.fields.file.url },
        mobile: { url: mobileImage.fields.file.url },
      }}
      fadeIn
    >
      <a
        className={cn(styles.content, styles[textAlignment])}
        href={`/${ctaLink}`}
        onClick={trackClick}
      >
        <h2 className={styles.title}>{title}</h2>
        <Button className={ctaClass} size="large" variant={buttonVariant}>
          {ctaText}
        </Button>
      </a>
    </BackgroundImage>
  );
};

export default FullWidthCTA;

import React, { useEffect, useState } from "react";
import { sortBy, indexOf, map } from "lodash/fp";
import type { Id } from "storefront/lib/Id";
import CapsulesCarousel from "storefront/components/CapsulesCarousel";
import moduleClicked from "../../../Analytics/EventCreators/moduleClicked";
import useAnalytics from "../../../hooks/useAnalytics";
import getCapsulesById from "../../../GrailedAPI/v1/Capsules/getCapsulesById";
import type {
  FeaturedCapsulesModule,
  TrackingItemProperties,
} from "../../../Contentful/types";
import type { Capsule } from "../../../Capsule";
import Heading from "../Heading";
import styles from "./FeaturedCapsules.module.scss";

type Props = {
  entry: FeaturedCapsulesModule;
  position: number;
  from: string;
};

const indexOfCapsule =
  (ids: Array<number>) =>
  (capsule: Capsule): number =>
    indexOf(capsule.id, ids);

const iteratees = (ids: Array<number>): Array<(capsule: Capsule) => number> => [
  indexOfCapsule(ids),
];

const sortCapsulesByIds = (
  ids: Array<Id>,
  capsules: Array<Capsule>,
): Array<Capsule> => {
  const numberIds: Array<number> = map(Number, ids);
  return sortBy(iteratees(numberIds), capsules);
};

const ctaProperties = (ctaText: string): TrackingItemProperties => ({
  itemType: "CTA",
  itemPosition: "CTA",
  itemName: ctaText,
  itemNameContentful: ctaText,
  imageUrl: "",
});

/**
 * Make CTA links use the pathname so that they work as expected on prod (grailed.com/collections)
 * and dev (localhost:5000/collections). This is needed because the Capsules module CTA link is
 * received from Contentful, which is passing an absolute URL.
 */
const getPathname = (link: string): string => {
  if (!link.includes("grailed.com")) {
    return link;
  }

  const url = new URL(link);
  return url.pathname;
};

const FeaturedCapsules = (props: Props) => {
  const { track } = useAnalytics();
  const [capsules, setCapsules] = useState<Array<Capsule>>([]);
  const { collectionIds } = props.entry.fields;

  useEffect(() => {
    getCapsulesById(collectionIds).then(({ data }) => {
      const sorted = sortCapsulesByIds(collectionIds, data);
      setCapsules(sorted);
    });
  }, [collectionIds]);

  const trackClicked =
    (properties: TrackingItemProperties) =>
    (_event: React.MouseEvent<HTMLAnchorElement>): void => {
      const { name, title } = props.entry.fields;
      const { position, from } = props;
      track(
        moduleClicked({
          ...properties,
          from,
          moduleType: "Featured Capsules",
          moduleName: title,
          moduleNameContentful: name,
          modulePosition: position,
        }),
      );
    };

  const { title, ctaText, ctaLink } = props.entry.fields;

  if (capsules.length === 0) return null;

  return (
    <div className={styles.featuredCapsules}>
      <Heading
        title={title}
        ctaText={ctaText}
        ctaLink={getPathname(ctaLink)}
        trackClick={trackClicked(ctaProperties(ctaText))}
      />
      <CapsulesCarousel
        trackClick={trackClicked}
        capsules={capsules}
        listingPageCarousel={false}
      />
    </div>
  );
};

export default FeaturedCapsules;

import type { UserSelf as User } from "storefront/User";
import type { Error } from "storefront/GrailedAPI/v1/Error";

/**
 * @namespace Authentication
 * @description Authentication is a data type representing a visitor's authentication state. There
 * are four states possible for this type:
 *   - Loading, when the async operation to check the visitor's state is in progress;
 *   - Unauthenticated, when the operation has completed and the visitor is not logged in;
 *   - Authenticated, when the operation has completed and the visitor is logged in; and
 *   - Failed, when something about the operation has thrown an error.
 *
 * Similar to the Resource data type, an Authentication is a snapshot of one moment in time, it is
 * not representative of the entire async operation, just the current state of that operation; if
 * you want to work with the potential future result of checking a visitor's authentication state,
 * you should check out Promises!
 *
 * Below, there are some helper functions to make working with this data type easier, as well as a
 * React Hook that can be used in place of managing component local state manually in every
 * component that needs to know about a visitor's authentication state.
 */

/**
 * @typedef Loading
 * @memberof Authentication
 * @description A type representing that the async operation to check the visitor's state is in
 * progress.
 */
type Loading = {
  type: "Loading";
};

/**
 * @typedef Unauthenticated
 * @memberof Authentication
 * @description A type representing that the operation has completed and the visitor is not logged
 * in.
 */
type Unauthenticated = {
  type: "Unauthenticated";
};

/**
 * @typedef Authenticated
 * @memberof Authentication
 * @description A type representing that the operation has completed and the visitor is logged in.
 */
export type Authenticated = {
  type: "Authenticated";
  user: User;
};

/**
 * @typedef Failed
 * @memberof Authentication
 * @description A type representing that something about the operation has thrown an error.
 */
type Failed = {
  type: "Failed";
  error: Error;
};

/**
 * @name Authentication
 * @memberof Authentication
 * @description A type representing a visitor's authentication state. There are four states
 * possible for this type:
 *   - Loading, when the async operation to check the visitor's state is in progress;
 *   - Unauthenticated, when the operation has completed and the visitor is not logged in;
 *   - Authenticated, when the operation has completed and the visitor is logged in; and
 *   - Failed, when something about the operation has thrown an error.
 *
 * Similar to the Resource data type, an Authentication is a snapshot of one moment in time, it is
 * not representative of the entire async operation, just the current state of that operation; if
 * you want to work with the potential future result of checking a visitor's authentication state,
 * you should check out Promises!
 */
export type Authentication = Loading | Unauthenticated | Authenticated | Failed;

/**
 * @name loading
 * @memberof Authentication
 * @type {Loading}
 * @description A value representing the Loading type. All loading authentications are represented
 * by this same exact instance, unlike the authenticated or failed states, because there is no
 * external value or error needed to construct this type.
 */
export const loading: Loading = {
  type: "Loading",
};

/**
 * @name unauthenticated
 * @memberof Authentication
 * @type {Unauthenticated}
 * @description A value representing the Unauthenticated type. All unauthenticated authentications
 * are represented by this same exact instance, unlike the authenticated or failed states, because
 * there is no external value or error needed to construct this type.
 */
export const unauthenticated: Unauthenticated = {
  type: "Unauthenticated",
};

/**
 * @name authenticated
 * @memberof Authentication
 * @description A value representing the Authenticated type. This type constructor is a function
 * because it needs the user object to be initialized.
 */
export const authenticated: (user: User) => Authenticated = (user) => ({
  type: "Authenticated",
  user,
});

/**
 * @name failed
 * @memberof Authentication
 * @description A nullary type representing the failed state of checking a visitor's auth status.
 * This type constructor is a function because it needs some error to be initialized.
 *
 * @param {Error} error - the error that caused the auth status check to fail
 */
export const failed: (error: Error) => Failed = (error) => ({
  type: "Failed",
  error,
});

import React, { useEffect, useState } from "react";
import { map } from "lodash/fp";
import { useTranslation } from "react-i18next";
import getFeaturedArticles from "storefront/GrailedAPI/v1/Articles/getFeaturedArticles";
import setUniqueIds from "storefront/lib/setUniqueIds";
import { Article, loadingArticle } from "storefront/Article";
import Heading from "../Heading";
import ArticleItem from "./ArticleItem";
import styles from "./DCOBlock.module.scss";

const ARTICLES_TO_SHOW = 3;
const INITIAL_ARTICLES = setUniqueIds<Article>(
  new Array(ARTICLES_TO_SHOW).fill(loadingArticle),
);

const DCOBlock = () => {
  const { t } = useTranslation("common");
  const [articles, setArticles] = useState<Array<Article>>(INITIAL_ARTICLES);

  useEffect(() => {
    getFeaturedArticles().then((featuredArticles) =>
      setArticles(featuredArticles),
    );
  }, []);

  return (
    <div className={styles.module}>
      <Heading
        title="Latest Articles"
        ctaLink={t("BLOG_LINK_PATH")}
        ctaText="More Articles"
      />

      <div className={styles.articles}>
        {map(
          (article) => (
            <ArticleItem article={article} key={article.id} />
          ),
          articles.slice(0, ARTICLES_TO_SHOW),
        )}
      </div>
    </div>
  );
};

export default DCOBlock;

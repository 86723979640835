import { set, keyBy } from "lodash/fp";
import type { Action } from "storefront/Action";
import type { Module } from "storefront/Contentful/types";
import * as ContentfulWrapper from "storefront/components/Modules/ContentfulPageWrapper";

export type State = {
  modules: Array<Module>;
};
const initialState: State = {
  modules: [],
};

const reducer: (arg0: State, arg1: Action) => State = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case ContentfulWrapper.Actions.CONTENTFUL_RESPONDED: {
      const transformed = keyBy("sys.id")(payload.modules);
      return set("modules", transformed, state);
    }

    default:
      return state;
  }
};

export default reducer;

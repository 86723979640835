import React, { lazy, useState } from "react";
import { useSelector } from "react-redux";
import type { User } from "storefront/User";
import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import Modal from "storefront/components/Modal";
import { routeMatches } from "../../lib/route";
import type { State } from "../../types/State";

const ViolationAcknowledger = lazy(
  () =>
    import(
      /* webpackChunkName: "ViolationAcknowledger" */
      "./Acknowledger"
    ),
);

const userSelector = (state: State) => state.session.currentUser;

const ViolationModal = () => {
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const user = useSelector<State, User>(userSelector);
  if (routeMatches("/about/terms")) return null;

  const closeModal = () => setAcknowledged(true);

  return (
    <Modal
      className="jumbo"
      isOpen={!!user.hasUnacknowledgedViolations && !acknowledged}
      onRequestClose={closeModal}
      hideCloseButton
      preventCloseOnClickOutside
    >
      <SuspenseWithFragment>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ currentUserId: Id; onAccept: () => void; }... Remove this comment to see the full error message */}
        <ViolationAcknowledger currentUserId={user.id} onAccept={closeModal} />
      </SuspenseWithFragment>
    </Modal>
  );
};

export default ViolationModal;

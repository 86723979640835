import React, { Suspense, useState, useEffect } from "react";

import Loading from "./Loading";

type Props = {
  children: React.ReactNode;
  message?: string;
  className?: string;
};

/**
 * @name SuspenseWithLoading
 * @description Wraps the React Component with React.Suspense and Loading Fallback while loading.
 */
const SuspenseWithLoading = ({
  children,
  message = "Loading...",
  className,
}: Props) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useEffect(() => setIsMounted(true), []);
  const fallback = <Loading message={message} className={className} />;
  return isMounted ? (
    <Suspense fallback={fallback}>{children}</Suspense>
  ) : (
    fallback
  );
};

export default SuspenseWithLoading;

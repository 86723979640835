import React from "react";
import LazyLoad from "react-lazyload";
import cn from "classnames";
import {
  FeedItem as FeedItemType,
  TrackingItemProperties,
  ItemType,
} from "storefront/Contentful/types";
import ContentfulPicture from "storefront/components/ContentfulPicture";
import Subhead from "storefront/components/Subhead";

import styles from "./FeedItem.module.scss";

type Props = {
  entry: FeedItemType;
  trackClick: (
    properties: TrackingItemProperties,
  ) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
  index: number;
  href: string;
  className?: string;
};

const FeedItem = ({ entry, trackClick, index, href, className }: Props) => {
  const { name, title } = entry.fields;
  // title here refers to the text under the image
  const imageFields = entry.fields.image.fields;
  const itemType: ItemType = "feed item";
  const properties = {
    itemType,
    itemPosition: index,
    itemName: name,
    itemNameContentful: name,
    imageUrl: imageFields.file.url,
  };

  return (
    <a
      className={cn(styles.feedItem, className)}
      href={href}
      onClick={trackClick(properties)}
      title={title || name}
    >
      <LazyLoad height={132} once offset={200}>
        <ContentfulPicture
          images={[
            {
              device: "mobile",
              size: {
                h: 150,
                w: 250,
              },
              src: imageFields.file.url,
            },
            {
              device: "tablet",
              size: {
                h: 150,
                w: 270,
              },
              src: imageFields.file.url,
            },
            {
              device: "desktop",
              size: {
                h: 245,
                w: 390,
              },
              src: imageFields.file.url,
            },
          ]}
          alt={imageFields.title}
          className={cn(styles.image, { [`${className}-Image`]: className })}
        />
      </LazyLoad>
      {title ? (
        <Subhead
          className={cn(styles.title, { [`${className}-Title`]: className })}
        >
          {title}
        </Subhead>
      ) : null}
    </a>
  );
};

export default FeedItem;

import React from "react";
import type { ComponentType } from "react";
import { take } from "lodash/fp";
import { connect } from "react-redux";
import type { GlobalState } from "storefront/GlobalState";
import type { Tracker } from "storefront/Analytics/Tracker";
import type { PageType } from "storefront/Analytics/Event";
import Heading from "storefront/components/ListingPage/Modules/Heading";
import type { Id } from "../../../lib/Id";
import type { Listing } from "../../../Listing";
import type { TrackingItemProperties } from "../../../Contentful/types";
import type { UserSelf } from "../../../User";
import { withAnalytics } from "../../../hooks/useAnalytics";
import moduleClicked from "../../../Analytics/EventCreators/moduleClicked";
import fetchByIds from "../../../Algolia/Listings/fetchByIds";
import getRecentlyViewed from "../../../RecentlyViewed/get";
import ListingCarousel from "../../ListingCarousel";
import ListingCarouselWrapper from "../../ListingCarousel/Wrapper";

type StateProps = {
  currentUser?: UserSelf;
};
type OwnProps = {
  from: string;
  position: number;
  currentListingId?: Id;
  pageType: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};
type Props = StateProps &
  OwnProps & {
    tracker: Tracker;
  };
type State = {
  listings: Array<Listing>;
};

const maybeRemove =
  (listingId: Id | null | undefined) =>
  (recentlyViewed: Set<Id>): Set<Id> => {
    if (listingId) recentlyViewed.delete(listingId);
    return recentlyViewed;
  };

const fetchListingsIfEnough = (ids: Set<Id>): Promise<Array<Listing>> => {
  if (ids.size >= 6) {
    return fetchByIds(Array.from(ids), "default");
  }

  return Promise.resolve([]);
};

/**
 * @name Modules.RecentlyViewed
 * @description Renders a Listing Carousel containing the current
 * user's recently viewed listings.
 * The user's Recently Viewed Listings are retrieved from localStorage.
 */
export class RecentlyViewed extends React.Component<Props, State> {
  state: State = {
    listings: [],
  };

  moduleName = "Recently Viewed";

  componentDidMount() {
    this.refreshListings();
  }

  componentDidUpdate(prevProps: Props) {
    const prevUser = prevProps.currentUser || {
      id: -1,
    };
    const nextUser = this.props.currentUser || {
      id: -1,
    };

    if (prevUser.id !== nextUser.id) {
      this.refreshListings();
    }
  }

  refreshListings = () => {
    const { currentUser, currentListingId } = this.props;
    getRecentlyViewed(currentUser)
      .then(maybeRemove(currentListingId))
      .then(fetchListingsIfEnough)
      .then((listings) =>
        this.setState({
          listings,
        }),
      );
  };

  trackClick: (arg0: TrackingItemProperties) => () => void =
    (properties) => () => {
      const { position, from, tracker } = this.props;
      tracker.track(
        moduleClicked({
          ...properties,
          from,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'ModuleTyp... Remove this comment to see the full error message
          moduleType: this.moduleName,
          moduleName: this.moduleName,
          moduleNameContentful: "Module: Recently Viewed",
          modulePosition: position,
        }),
      );
    };

  render() {
    const { listings } = this.state;
    const { pageType, pageTypeIdentifier, pageTypeName } = this.props;
    if (listings.length < 6) return null;
    return (
      <ListingCarouselWrapper className="Module--RecentlyViewed">
        <Heading title="Recently Viewed" />
        <ListingCarousel
          listings={take(12, listings)}
          trackClick={this.trackClick}
          numOfItemsToShow={6}
          moduleName={this.moduleName}
          moduleType={this.moduleName}
          pageType={pageType}
          pageTypeIdentifier={pageTypeIdentifier}
          pageTypeName={pageTypeName}
          from="recently_viewed"
        />
      </ListingCarouselWrapper>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  currentUser: state.session.currentUser,
});

const ConnectedRecentlyViewed: ComponentType<OwnProps> = connect(
  mapStateToProps,
)(withAnalytics(RecentlyViewed));
export default ConnectedRecentlyViewed;

import { get } from "storefront/GrailedAPI/request";
import { Id } from "storefront/lib/Id";
import { Options } from "storefront/GrailedAPI/request/Options";

type Params = {
  // NOTE: the keys string param is comma-separated, e.g. "key1,key2"
  keys: string;
  userId?: Id;
};

export type FlipperStates = Record<string, { enabled: boolean }>;

type Response = {
  data: FlipperStates;
};

const unpack = (res: Response): FlipperStates => res.data;

const getFeatures = (
  params: Params,
  options?: Options,
): Promise<FlipperStates> =>
  get<Response>("/api/flipper", params, options).then(unpack);

export default getFeatures;

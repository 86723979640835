import React, { useContext } from "react";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import { Context as AnalyticsContext } from "../../../../hooks/useAnalytics";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'propertyName' implicitly has an 'any' t... Remove this comment to see the full error message
const generateTrackClick = (propertyName) => ({
  object: "button",
  action: "clicked",
  properties: {
    name: propertyName,
    from: "sub_header",
  },
});

type SimpleLinkProps = {
  title: string;
  href: string;
  trackingPropertyName: string;
  targetBlank?: boolean;
};

const SimpleLink = ({
  title,
  href,
  trackingPropertyName,
  targetBlank = false,
}: SimpleLinkProps) => {
  const { track } = useContext(AnalyticsContext);

  const onClick = () => {
    track(buttonClicked(trackingPropertyName, "sub_header"));
    track(navigationClicked(trackingPropertyName, "sub_header"));
  };

  return (
    <div className="-category">
      <span className="--link">
        <a
          href={href}
          onClick={onClick}
          rel={targetBlank ? "noreferrer noopener" : undefined}
          target={targetBlank ? "_blank" : undefined}
        >
          {title}
        </a>
      </span>
    </div>
  );
};

export const StaffPicksLink = () => (
  <SimpleLink
    title="Staff Picks"
    href="/shop/staff-picks"
    trackingPropertyName="staff_picks"
  />
);
export const SneakersLink = () => (
  <SimpleLink
    title="Sneakers"
    href="/categories/sneakers"
    trackingPropertyName="sneakers"
  />
);
export const CollectionsLink = () => (
  <SimpleLink
    title="Collections"
    href="/collections"
    trackingPropertyName="collections"
  />
);

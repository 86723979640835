import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { propOr } from "lodash/fp";
import FlashManager from "storefront/lib/flash/FlashManager";
import toggleDesignerFollow from "storefront/Analytics/EventCreators/toggleDesignerFollow";
import type { PageType } from "storefront/Analytics/Event";
import type { GlobalState } from "storefront/GlobalState";
import type { Designer } from "storefront/Algolia/Designer";
import type { UserSelf } from "storefront/User";
import getPhotoUrls from "storefront/GrailedAPI/v1/Designers/getPhotoUrls";
import type {
  DesignersForYouModule,
  TrackingItemProperties,
} from "storefront/Contentful/types";
import fetchByIds from "storefront/Algolia/Designers/fetchByIds";
import type { Id } from "storefront/lib/Id";
import { completed } from "storefront/lib/Resource";
import getRecommendedDesignerIds from "storefront/GrailedAPI/v1/Users/Recommendations/getRecommendedDesignerIds";
import followDesigners from "storefront/GrailedAPI/v1/Users/Follows/followDesigners";
import unfollowDesigners from "storefront/GrailedAPI/v1/Users/Follows/unfollowDesigners";
import moduleClicked, {
  ModuleType,
} from "storefront/Analytics/EventCreators/moduleClicked";
import useAnalytics from "storefront/hooks/useAnalytics";
import DesignerCarousel from "../../DesignerCarousel";
import type { CarouselDesigner } from "../../DesignerCarousel";
import Heading from "../Heading";
import styles from "./index.module.scss";

export type ModuleViewedEvent = {
  object: "module";
  action: "viewed";
  properties: {
    moduleType: string;
    moduleName: string;
    moduleNameContentful: string;
    modulePosition: number;
    isPersonalized?: boolean;
    from: string;
  };
};

type Props = {
  entry?: DesignersForYouModule;
  position: number;
  from: string;
  onRender?: () => unknown;
  pageType: PageType;
};

type State =
  | {
      status: "loading";
    }
  | {
      status: "success";
      designers: Array<CarouselDesigner>;
      isPersonalized: boolean;
    }
  | {
      status: "failure";
      error: Error;
    };

const MAX_DESIGNERS_TO_FETCH = 18;

/**
 * @description Carousel of Designer Recommendations.
 */
const DesignersForYou = ({
  entry,
  position,
  from,
  onRender,
  pageType,
}: Props) => {
  const { track } = useAnalytics();

  const currentUser: UserSelf | null | undefined = useSelector<
    GlobalState,
    UserSelf | null | undefined
  >((state) => state.session.currentUser);

  const [state, setState] = useState<State>({
    status: "loading",
  });

  const name = propOr("Designers For You", "fields.name", entry);
  const title = propOr("Popular Designers", "fields.title", entry);
  const ctaText = propOr("", "fields.ctaText", entry);
  const ctaLink = propOr("", "fields.ctaLink", entry);
  const trackCTAProperties: TrackingItemProperties = {
    itemType: "CTA",
    itemPosition: "CTA",
    itemName: ctaText,
    itemNameContentful: ctaText,
    imageUrl: ctaLink,
  };
  const moduleType: ModuleType = "Designer Cards";
  const moduleProps = {
    moduleType,
    moduleName: name,
    moduleNameContentful: name,
    modulePosition: position,
  };

  useEffect(() => {
    if (!currentUser?.id) {
      return;
    }

    getRecommendedDesignerIds(currentUser.id)
      .then((result) =>
        Promise.all([
          fetchByIds(result.designerIds.slice(0, MAX_DESIGNERS_TO_FETCH)),
          Promise.resolve(result.personalized),
        ]),
      )
      .then(([designersData, personalized]) => {
        if (designersData.length) {
          track({
            object: "module",
            action: "viewed",
            properties: { ...moduleProps, isPersonalized: personalized, from },
          });
        }

        setState({
          status: "success",
          isPersonalized: personalized,
          designers: designersData.map((designer: Designer) => ({
            id: designer.id,
            slug: designer.slug,
            name: designer.name,
            listingsCount: designer.listingsCount,
            isFollowing: completed(false),
            photoUrls: [],
          })),
        });
      })
      .catch((err) => {
        setState({
          status: "failure",
          error: err,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (state.status !== "success") {
      return;
    }

    getPhotoUrls(state.designers.map((d) => d.id))
      .then((photoUrlsById) => {
        setState({
          ...state,
          designers: state.designers.map((designer: CarouselDesigner) => {
            const designerPhotoUrls = photoUrlsById[designer.id];
            return {
              ...designer,
              photoUrls: designerPhotoUrls || [],
            };
          }),
        });
      })
      .catch((err) => {
        setState({
          status: "failure",
          error: err,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, state.status]);

  // NOTE: I've added this `onRender` call as a way to allow this component to
  // tell some outside context whether it has rendered (with > 0 designers) or not.
  // It's a bit of a hack (pls don't kill me) to let me integrate this component into the
  // Personalized For You page which has some exotic logic for passing in the
  // `position` prop. The idea here is that this is a simple callback that we can use
  // for now without a larger refactor of the Personalized For You at this time.
  useEffect(() => {
    if (state.status !== "success") {
      return;
    }

    if (onRender && state.designers.length) {
      onRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.status, onRender]);

  if (!currentUser) return null;
  if (state.status === "loading") return null;
  if (state.status === "failure") return null;
  if (state.status === "success" && !state.designers.length) return null;

  const trackClickCTA = (_event: React.SyntheticEvent<any>): void => {
    track(
      moduleClicked({
        ...trackCTAProperties,
        ...moduleProps,
        from,
        // @ts-expect-error TODO: Determine if this property is used/desired in the Module Clicked event
        isPersonalized: state.isPersonalized,
      }),
    );
  };

  const trackClick =
    (designer: CarouselDesigner, index: number) =>
    (_e: React.SyntheticEvent<any>): void => {
      track(
        moduleClicked({
          from,
          ...moduleProps,
          itemName: `${designer.id}/${designer.name}`,
          itemNameContentful: `${designer.id}/${designer.name}`,
          itemPosition: index,
          itemType: "designer card",
          imageUrl: "",
          // @ts-expect-error TODO: Determine if this property is used/desired in the Module Clicked event
          isPersonalized: state.isPersonalized,
        }),
      );
    };

  const onClickFollow = (id: Id) => {
    const index = state.designers.findIndex((d) => d.id === id);
    const newDesigners = [...state.designers];
    const designer = newDesigners[index];

    if (designer.isFollowing.type !== "Completed") return;

    const followsMethod = designer.isFollowing.value
      ? unfollowDesigners
      : followDesigners;
    const errorMessage = `There was a problem
      ${designer.isFollowing.value ? "unfollowing" : "following"}
      this designer. Please try again.`;

    followsMethod(currentUser.id, [id])
      .then(() => {
        if (designer.isFollowing.type !== "Completed") return;

        designer.isFollowing = completed(!designer.isFollowing.value);
        track(
          toggleDesignerFollow({
            designers: [id],
            designerNames: [designer.name],
            isFollow: designer.isFollowing.value,
            from,
            pageType,
            userId: currentUser.id,
          }),
        );
        setState({ ...state, designers: newDesigners });
      })
      .catch(() => {
        const flashManager = FlashManager.getInstance();
        flashManager.alert(errorMessage);
      });
  };

  return (
    <div className={styles.container}>
      <Heading
        title={title}
        trackClick={trackClickCTA}
        ctaLink={ctaLink}
        ctaText={ctaText}
      />
      <DesignerCarousel
        carouselDesigners={state.designers}
        onClickFollow={onClickFollow}
        numOfItemsToShow={6}
        trackClick={trackClick}
      />
    </div>
  );
};

export default DesignersForYou;

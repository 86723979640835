import type { Id } from "../lib/Id";
import getListingViews from "../GrailedAPI/v1/Users/RecentViews/Listings/getListingViews";
import type { User } from "../User";
import getFromLocalStorage from "./getFromLocalStorage";

const get = (
  user: User | null | undefined,
  win: typeof window = window,
): Promise<Set<Id>> => {
  if (user && user.id && user.id !== -1) return getListingViews(user.id);
  return Promise.resolve(getFromLocalStorage(win));
};

export default get;

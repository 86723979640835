/* eslint-disable import/prefer-default-export */
import { camelize } from "camelscore";
import { compose, prop, filter } from "lodash/fp";
import { IndexedList } from "storefront/lib/IndexedList";
import memoize from "storefront/lib/Function/memoize";
import { Id } from "storefront/lib/Id";
import { Strata } from "storefront/Strata";
import { SizesByCategory } from "storefront/Size";
import { Categories } from "storefront/Categories";
import { Badge } from "storefront/Badge";
import { Country } from "storefront/Country";
import { SellerBadge } from "storefront/SellerBadge";
import { MeasurementType } from "storefront/MeasurementType";
import { Host } from "storefront/Contentful/Host";
import { Config as ContentfulConfig } from "storefront/Contentful/Config";
import { Name as ShippingRegionName } from "storefront/Shipping/Region";
import { AlgoliaConfig } from "./AlgoliaConfig";

export type Algolia = AlgoliaConfig;

type Contentful$Space = {
  spaceId: Id;
  accessToken: string;
  entryIds?: IndexedList<string | IndexedList<string>>;
};

type Contentful = {
  host: Host;
  spaces: IndexedList<Contentful$Space>;
};

export type Environment = "production" | "development";

type GoogleAnalytics = {
  domain: string;
  trackingId: string;
};

type ScoreOption =
  | "returning_scammer"
  | "charge_back"
  | "inauthentic"
  | "off_site"
  | "user_feedback"
  | "mod_review"
  | "other";

type ReasonOption =
  | "high_risk"
  | "suspicious"
  | "unknown"
  | "low_risk"
  | "trustworthy";

type TrustOptions = {
  scores: Array<ScoreOption>;
  reasons: Array<ReasonOption>;
};

type Recaptcha = {
  siteKey?: string;
  siteKeyInvisible?: string;
};

type UserReasons = Record<string, string>;

// PublicConfigSerializer
export type PublicConfig = {
  algolia: AlgoliaConfig;
  appleSignInClientId: string;
  appleSignInRedirectUri: string;
  badges: Array<Badge>;
  categories: Categories;
  contentful: Contentful;
  countries: Array<Country>;
  designersRequiringSneakerId: Array<number>;
  env: Environment;
  facebookAppId: number;
  googleAnalytics: GoogleAnalytics;
  googleOauthClientId: string;
  inBeta: boolean;
  internationalTaxable: Array<Country>;
  listingMaxPrice: number;
  listingMinPrice: number;
  locations: Array<ShippingRegionName>;
  measurementTypes: Array<MeasurementType>;
  recaptcha: Recaptcha;
  refundWithheldFundsUserReasons: UserReasons;
  releaseVersion: string;
  requiresPostalCode: Array<string>;
  sellerBadges: Array<SellerBadge>;
  sizes: SizesByCategory;
  stratas: Array<Strata>;
  stripePublishableKey: string;
  trustOptions: TrustOptions;
  urls: IndexedList<string>;
  vacationModeLimit: number;
};

export const empty: PublicConfig = {
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
  algolia: {},
  appleSignInClientId: "",
  appleSignInRedirectUri: "",
  badges: [],
  categories: {},
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
  contentful: {},
  countries: [],
  designersRequiringSneakerId: [],
  env: "development",
  facebookAppId: 0,
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
  googleAnalytics: {},
  googleOauthClientId: "",
  inBeta: false,
  internationalTaxable: [],
  listingMaxPrice: 0,
  listingMinPrice: 0,
  locations: [],
  measurementTypes: [],
  recaptcha: {},
  refundWithheldFundsUserReasons: {},
  releaseVersion: "",
  requiresPostalCode: [],
  sellerBadges: [],
  sizes: {},
  stratas: [],
  stripePublishableKey: "",
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
  trustOptions: {},
  urls: {},
  vacationModeLimit: 0,
};

export const fromWindow: (arg0: typeof window) => PublicConfig = memoize(
  ({ PUBLIC_CONFIG }) => camelize(PUBLIC_CONFIG),
);

export const searchableStrata: (arg0: PublicConfig) => Array<Strata> = compose([
  filter("searchable"),
  prop("stratas"),
]);

export const getSizesFromConfig = (config: PublicConfig): SizesByCategory =>
  config.sizes;

export const getStratasFromConfig = (config: PublicConfig): Array<Strata> =>
  config.stratas;

export const getBadgesFromConfig = (config: PublicConfig): Array<Badge> =>
  config.badges;

export const getRequiresPostalCodeFromConfig = (
  config: PublicConfig,
): Array<string> => config.requiresPostalCode;

export const getContentfulConfigs = (
  config: PublicConfig,
): ContentfulConfig => ({
  host: config.contentful.host,
  accessToken: config.contentful.spaces.grailed.accessToken,
  spaceId: config.contentful.spaces.grailed.spaceId,
});

export const getCountriesFromConfig = (config: PublicConfig): Array<Country> =>
  config.countries;

export const getLocationsFromConfig = (
  config: PublicConfig,
): Array<ShippingRegionName> => config.locations;

export const getSellerBadges = (config: PublicConfig): Array<SellerBadge> =>
  config.sellerBadges;

export const getTaxableCountries = (config: PublicConfig): Array<Country> =>
  config.internationalTaxable;

import React from "react";
import cn from "classnames";
import ReactMarkdown from "react-markdown";
import { AlertBannerModule } from "storefront/Contentful/types";
import styles from "storefront/components/SiteBanner/AlertBanner.module.scss";

type Props = {
  entry: AlertBannerModule;
};

function AlertBanner(props: Props) {
  const { message, alertLevel } = props.entry.fields;

  return (
    <ReactMarkdown
      className={cn(styles.root, {
        [styles.red]: alertLevel.toLowerCase() === "red",
        [styles.blue]: alertLevel.toLowerCase() === "blue",
        [styles.yellow]: alertLevel.toLowerCase() === "yellow",
        [styles.green]: alertLevel.toLowerCase() === "green",
        [styles.black]: alertLevel.toLowerCase() === "black",
      })}
      source={message}
    />
  );
}

export default AlertBanner;

import React from "react";
import classnames from "classnames";
import BackgroundImage from "storefront/components/Image/BackgroundImage";
import SmallTitle from "storefront/components/SmallTitle";
import Title2 from "storefront/components/Title2";
import ButtonLink from "storefront/components/ButtonLink";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import useAnalytics from "storefront/hooks/useAnalytics";
import useMediaQuery, {
  LARGER_THAN_MOBILE,
} from "storefront/hooks/useMediaQuery";
import { DCOEntryPoint as DCOEntryPointType } from "storefront/Contentful/types";
import styles from "./DCOEntryPoint.module.scss";

type Props = {
  entry: DCOEntryPointType;
  position: number;
  from: string;
};

const DCOEntryPoint = ({ entry, position, from }: Props) => {
  const { track } = useAnalytics();
  const {
    tag,
    headline,
    ctaText,
    ctaLink,
    mobileImage,
    desktopImage,
    shouldMobileHaveBlackOverlay,
    shouldDesktopHaveBlackOverlay,
  } = entry.fields;
  const largerThanMobile = useMediaQuery(LARGER_THAN_MOBILE);
  const shouldShowBlackOverlay = largerThanMobile
    ? shouldDesktopHaveBlackOverlay
    : shouldMobileHaveBlackOverlay;

  const trackModuleClicked = (
    _event: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    const moduleName: string = tag ? `${tag} ${headline}` : `${headline}`;
    track(
      moduleClicked({
        from,
        imageUrl: desktopImage.fields.file.url,
        itemName: ctaText,
        itemNameContentful: ctaText,
        itemPosition: "CTA",
        itemType: "CTA",
        moduleName,
        moduleNameContentful: moduleName,
        modulePosition: position,
        moduleType: "DCO Entry Point",
      }),
    );
  };

  return (
    <BackgroundImage
      TagName="div"
      className={classnames(styles.module, {
        [styles.blackOverlay]: shouldShowBlackOverlay,
      })}
      sources={{
        desktop: {
          url: desktopImage.fields.file.url,
        },
        mobile: {
          url: mobileImage.fields.file.url,
        },
      }}
    >
      {tag ? <SmallTitle className={styles.tag}>{tag}</SmallTitle> : null}

      <Title2 className={styles.headline}>{headline}</Title2>

      <ButtonLink
        href={ctaLink}
        onClick={trackModuleClicked}
        className={styles.cta}
        variant="secondary"
        size="large"
      >
        {ctaText}
      </ButtonLink>
    </BackgroundImage>
  );
};

export default DCOEntryPoint;

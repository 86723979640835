import React from "react";
import cn from "classnames";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import {
  VisualFiltersModule,
  TrackingItemProperties,
  ItemType,
} from "storefront/Contentful/types";
import Heading from "../Heading";
import FilterItem from "./FilterItem";

import styles from "./VisualFilters.module.scss";

type Props = {
  entry: VisualFiltersModule;
  from: string;
  position: number;
};

/**
 * @name Modules.VisualFilters
 * @description A Module serving Filters that apply when clicked. Note: this is
 *  being phased out because this UX paradigm is confusing.
 */
const VisualFilters = ({ entry, from, position }: Props) => {
  const { track } = useAnalytics();

  const handleFilterClick =
    (properties: TrackingItemProperties) =>
    (_event: React.MouseEvent<HTMLAnchorElement>) => {
      const { name, title } = entry.fields;

      track(
        moduleClicked({
          ...properties,
          from,
          moduleName: title,
          moduleNameContentful: name,
          modulePosition: position,
          moduleType: "Visual Filters",
        }),
      );
    };

  const { title, visualFilterItems } = entry.fields;

  return (
    <div className={styles.visualFilters}>
      <Heading title={title} />

      <div
        className={cn(styles.items, {
          [styles.four]: visualFilterItems.length === 4,
          [styles.six]: visualFilterItems.length === 6,
        })}
      >
        {visualFilterItems.map((item, index) => {
          const { image, name } = item.fields;

          const itemType: ItemType = "feed item";

          const properties = {
            itemType,
            itemPosition: index,
            itemName: name,
            itemNameContentful: name,
            imageUrl: image.fields.file.url,
          };

          return (
            <FilterItem
              entry={item}
              key={item.sys.id}
              handleClick={handleFilterClick(properties)}
              className={styles.item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VisualFilters;

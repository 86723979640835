import React from "react";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import type { Id } from "storefront/lib/Id";
import type { PageType } from "storefront/Analytics/Event";
import { PageEntry } from "storefront/Contentful/types";
import * as Actions from "./Actions";
import withContentful from "../../../Contentful/withContentful";
import ContentfulPageWrapper from "./ContentfulPageWrapper";

type Props = {
  baseClassName: string;
  from: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

const ContentfulPageWithContentful = withContentful<PageEntry, Props>(
  "page",
  ContentfulPageWrapper,
);

type PageProps = {
  baseClassName: string;
  from: string;
  id: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

const ContentfulPage = ({
  baseClassName,
  from,
  id,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: PageProps) => {
  const publicConfig = usePublicConfig();
  const config = {
    host: publicConfig.contentful.host,
    accessToken: publicConfig.contentful.spaces.grailed.accessToken,
    spaceId: publicConfig.contentful.spaces.grailed.spaceId,
  };
  return (
    <ContentfulPageWithContentful
      config={config}
      from={from}
      baseClassName={baseClassName}
      query={{
        "sys.id": id,
        include: 10,
      }}
      pageType={pageType}
      pageTypeIdentifier={pageTypeIdentifier}
      pageTypeName={pageTypeName}
    />
  );
};

export default ContentfulPage;
export { Actions };

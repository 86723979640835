import React from "react";
import classnames from "classnames";
import Callout from "../Callout";
import css from "./Loading.module.scss";

type Props = {
  message?: string;
  className?: string;
};

const Loading = ({ message = "Loading", className = "" }: Props) => (
  <div className={classnames(css.root, className)}>
    {message ? <Callout className={css.message}>{message}</Callout> : null}
    <Callout as="span" className={css.icon} />
  </div>
);

export default Loading;

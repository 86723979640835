import { prop } from "lodash/fp";
import type { Dispatch } from "redux";
import type { Action } from "storefront/Action";
import type { PageEntry } from "storefront/Contentful/types";

export const CONTENTFUL_RESPONDED = "Modules.Contentful.RESPONDED";
export const contentfulResponded: (
  arg0: PageEntry,
) => (arg0: Dispatch<Action>) => void = (page) => (dispatch) => {
  const modules = prop("fields.modules", page);
  dispatch({
    type: CONTENTFUL_RESPONDED,
    payload: {
      modules,
    },
  });
};

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash/fp";
import { useSelector } from "react-redux";
import { GlobalState } from "storefront/GlobalState";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked, {
  ModuleProperties,
} from "storefront/Analytics/EventCreators/moduleClicked";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import { Listing } from "storefront/Listing";
import {
  DiscoverDailyCarouselModule,
  TrackingItemProperties,
} from "storefront/Contentful/types";
import { UserSelf } from "storefront/User";
import getRecommendedSimilarListings from "storefront/GrailedAPI/v1/Users/Recommendations/getRecommendedSimilarListings";
import ListingCarousel from "storefront/components/ListingCarousel";
import ListingCarouselWrapper from "storefront/components/ListingCarousel/Wrapper";
import Heading from "../Heading";
import styles from "./DiscoverDailyCarousel.module.scss";

type Props = {
  entry: DiscoverDailyCarouselModule;
  position: number;
  from: string;
  onRender?: () => unknown;
  pageType: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

type State =
  | {
      status: "loading";
    }
  | {
      status: "success";
      listings: Array<Listing>;
    }
  | {
      status: "failure";
      error: Error;
    };

const MODULE_TYPE = "Listing Carousel";

const headingAnalyticsProps = (
  entry: DiscoverDailyCarouselModule,
  position: number,
  from: string,
): ModuleProperties => ({
  moduleType: MODULE_TYPE,
  moduleName: entry.fields.title,
  moduleNameContentful: entry.fields.name,
  modulePosition: position,
  itemType: "CTA",
  itemPosition: "CTA",
  itemName: entry.fields.ctaText,
  itemNameContentful: entry.fields.ctaText,
  imageUrl: entry.fields.ctaLink,
  from,
});

const listingAnalyticsProps = (
  listingProps: TrackingItemProperties,
  entry: DiscoverDailyCarouselModule,
  position: number,
  from: string,
): ModuleProperties => ({
  ...listingProps,
  moduleType: MODULE_TYPE,
  moduleName: entry.fields.title,
  moduleNameContentful: entry.fields.name,
  modulePosition: position,
  imageUrl: entry.fields.ctaLink,
  from,
});

/**
 * @description This is a carousel of listings that are generated by the
 * Grailed Recommender - this component will only render if the user is logged
 * in and the backend has results. This component also depends on a Contentful
 * entry for some of it's display content.
 */
const DiscoverDailyCarousel = ({
  entry,
  position,
  from,
  onRender,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: Props) => {
  const { title, ctaLink, ctaText, fullWidth } = entry.fields;

  const { track } = useAnalytics();

  const currentUser: UserSelf | null | undefined = useSelector<
    GlobalState,
    UserSelf | null | undefined
  >((state) => state.session.currentUser);

  const [state, setState] = useState<State>({
    status: "loading",
  });

  useEffect(() => {
    if (currentUser && state.status === "loading") {
      getRecommendedSimilarListings(currentUser.id)
        .then((result) => {
          setState({
            status: "success",
            listings: result,
          });
        })
        .catch((err) => {
          setState({
            status: "failure",
            error: err,
          });
        });
    }
  }, [currentUser, state]);

  if (state.status === "loading") return null;

  if (state.status === "failure") return null;

  if (isEmpty(state.listings)) return null;

  // NOTE: I've added this `onRender` call as a way to allow this component to
  // tell some outside context whether it has rendered or not. It's a bit of a
  // hack (pls don't kill me) to let me integrate this component into the
  // Personalized ForYou page which has some exotic logic for passing in the
  // `position` prop. The idea here is that this is a simple callback that we can use
  // for now without a larger refactor of the Personalized ForYou at this time.
  if (onRender) onRender();

  return (
    <ListingCarouselWrapper
      className={classNames(styles.module, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <Heading
        title={title}
        trackClick={() => {
          track(moduleClicked(headingAnalyticsProps(entry, position, from)));
        }}
        ctaLink={ctaLink}
        ctaText={ctaText}
      />
      <ListingCarousel
        listings={state.listings}
        trackClick={(listingProps) => () => {
          track(
            moduleClicked(
              listingAnalyticsProps(listingProps, entry, position, from),
            ),
          );
        }}
        numOfItemsToShow={6}
        moduleName={title}
        moduleType={MODULE_TYPE}
        pageType={pageType}
        pageTypeIdentifier={pageTypeIdentifier}
        pageTypeName={pageTypeName}
        from="for_you"
      />
    </ListingCarouselWrapper>
  );
};

export default DiscoverDailyCarousel;

import { Asset } from "contentful";
import React from "react";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import { LARGER_THAN_MOBILE, MOBILE } from "storefront/hooks/useMediaQuery";
import ButtonLink from "storefront/components/ButtonLink";
import Title1 from "storefront/components/Title1";
import Body from "storefront/components/Body";
import AutoplayVideo from "storefront/components/AutoplayVideo";

import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import css from "./BigVideoModule.module.scss";

type Fields = {
  callsToActions: Array<[string, string]>;
  desktopPoster: Asset;
  desktopVideo: Asset;
  headline: string;
  mobilePoster: Asset;
  mobileVideo: Asset;
  name: string;
  subheadline: string;
};

// NOTE: We repurposed the Search CTA content type because we are over our content type limit for
// our Contentful plan. Contentful does not allow changing the content type id ("searchCta") and we
// cannot delete old content types and re-create them (we have 90 content types on a plan that
// allows for 48 content types). [Evan 2022-10-13]
type BigVideoModuleEntry = TypedEntry<"searchCta", Fields>;

type Props = {
  entry: BigVideoModuleEntry;
  from: string;
  pageType: string;
  pageTypeIdentifier?: unknown;
  pageTypeName?: unknown;
  position: number;
};

const BigVideoModule = ({ entry, from, position }: Props) => {
  const {
    headline,
    subheadline,
    callsToActions,
    desktopVideo,
    desktopPoster,
    mobileVideo,
    mobilePoster,
    name,
  } = entry.fields;
  const { track } = useAnalytics();

  const trackModuleClicked = (itemName: string, itemPosition: number) => {
    track(
      moduleClicked({
        from,
        itemName,
        itemNameContentful: itemName,
        itemPosition,
        itemType: "CTA",
        moduleName: headline,
        moduleNameContentful: name,
        modulePosition: position,
        moduleType: "Big Video Module",
      }),
    );
  };

  return (
    <section className={css.root}>
      {headline ? <Title1 className={css.headline}>{headline}</Title1> : null}

      {subheadline ? (
        <Body className={css.subheadline}>{subheadline}</Body>
      ) : null}

      <div className={css.actions}>
        {callsToActions.map(([label, path], index) => (
          <ButtonLink
            key={path}
            size="large"
            variant="primary"
            href={path}
            className={css.action}
            onClick={() => trackModuleClicked(label, index)}
          >
            {label}
          </ButtonLink>
        ))}
      </div>

      <AutoplayVideo
        className={css.video}
        sources={[
          {
            src: mobileVideo.fields.file.url,
            type: mobileVideo.fields.file.contentType,
            poster: mobilePoster.fields.file.url,
            media: MOBILE,
            description: mobilePoster.fields.description,
          },
          {
            src: desktopVideo.fields.file.url,
            type: desktopVideo.fields.file.contentType,
            poster: desktopPoster.fields.file.url,
            media: LARGER_THAN_MOBILE,
            description: desktopPoster.fields.description,
          },
        ]}
        fallback={{
          src: desktopVideo.fields.file.url,
          type: desktopVideo.fields.file.contentType,
          poster: desktopPoster.fields.file.url,
          description: desktopPoster.fields.description,
        }}
      />
    </section>
  );
};

export default BigVideoModule;

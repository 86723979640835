import React, { useEffect, useState } from "react";
import { compact, compose, map, keyBy } from "lodash/fp";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import getDetailPages from "storefront/GrailedAPI/v1/DetailPages/getDetailPages";
import {
  DesignerGridModule,
  TrackingItemProperties,
} from "storefront/Contentful/types";
import { DesignerDetailPage } from "storefront/DetailPage";
import Heading from "../Heading";
import { DesignerItem } from "./DesignerItem";
import styles from "./DesignerGrid.module.scss";

type FlattenedDesigner = {
  name: string;
  slug: string;
  thumbnailUrl: string | null;
};

type Props = {
  entry: DesignerGridModule;
  from: string;
  position: number;
};

const flattenDesigners = (
  designers: Array<DesignerDetailPage>,
  orderedDesignerSlugs: Array<string>,
): Array<FlattenedDesigner> => {
  // Need to reorder the response because
  // designers is not ordered the same as orderedDesignerSlugs
  const keyedBySlug = keyBy("slug", designers);

  return compose([
    map((designerDetailPage: DesignerDetailPage) => ({
      ...designerDetailPage,
      name: designerDetailPage.resource.name,
    })),
    compact,
    map((designerSlug: string) => keyedBySlug[designerSlug]),
  ])(orderedDesignerSlugs);
};

const DesignerGrid = ({ entry, from, position }: Props) => {
  const { track } = useAnalytics();
  const { ctaLink, ctaText, name, title, designerSlugs } = entry.fields;
  const [designersFlattened, setDesignersFlattened] = useState<
    Array<FlattenedDesigner>
  >([]);

  useEffect(() => {
    getDetailPages<DesignerDetailPage>({
      slugs: designerSlugs,
      type: "DesignerDetailPage",
    }).then((res) => {
      setDesignersFlattened(flattenDesigners(res.data, designerSlugs));
    });
  }, [designerSlugs]);

  if (designersFlattened.length < 3) {
    return null;
  }

  const trackClick =
    (properties: TrackingItemProperties) =>
    (_event: React.MouseEvent<HTMLAnchorElement>): void => {
      track(
        moduleClicked({
          ...properties,
          from,
          moduleType: "Designer Grid",
          moduleName: title,
          moduleNameContentful: name,
          modulePosition: position,
        }),
      );
    };

  return (
    <div className={styles.wrapper}>
      <Heading
        ctaLink={ctaLink}
        ctaText={ctaText}
        title={title}
        trackClick={trackClick({
          imageUrl: "",
          itemName: ctaText,
          itemNameContentful: name,
          itemPosition: "CTA",
          itemType: "CTA",
        })}
      />
      <div className={styles.designerGrid}>
        {designersFlattened.map((designer, index) => (
          <DesignerItem
            key={designer.slug}
            name={designer.name}
            slug={designer.slug}
            thumbnailUrl={designer.thumbnailUrl}
            trackClick={trackClick({
              imageUrl: designer.thumbnailUrl || "",
              itemName: designer.name,
              itemNameContentful: name,
              itemPosition: index,
              itemType: "designer",
            })}
          />
        ))}
      </div>
    </div>
  );
};

export default DesignerGrid;

import React from "react";
import classnames from "classnames";
import { isAfter, addDays } from "date-fns/fp";
import ContentfulPicture from "storefront/components/ContentfulPicture";
import type { HeatwaveSiteBannerEntry } from "./types";

type Props = {
  entry: HeatwaveSiteBannerEntry;
};
type State = {
  dismissed: boolean;
};
/**
 * @name GlobalHeader.SiteBanner.HeatwaveSiteBanner
 * @description Site banner type for the Heatwave promotion
 * @param {Props} props
 * @returns {React$Element<*>}
 */

class HeatwaveSiteBanner extends React.Component<Props, State> {
  state: State = {
    dismissed: true,
  };

  componentDidMount() {
    try {
      const expiration = window.localStorage.getItem(
        "heatwave_banner_expiration",
      );

      if (!expiration || isAfter(new Date(expiration))(new Date())) {
        window.localStorage.removeItem("heatwave_banner_expiration");
        this.setState({
          dismissed: false,
        });
      }
    } catch (err) {}
  }

  handleDismiss = () => {
    try {
      const expiration = addDays(1)(new Date());
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
      window.localStorage.setItem("heatwave_banner_expiration", expiration);
    } catch (err) {}

    this.setState({
      dismissed: true,
    });
  };

  render() {
    const { ctaUrl, ctaLabel, title, image, urlExceptionsRegex, dismissable } =
      this.props.entry.fields;
    const { dismissed } = this.state;

    if (
      urlExceptionsRegex &&
      window.location.pathname.match(urlExceptionsRegex)
    ) {
      return <div />;
    }

    return (
      <div
        className={classnames("Editorial--SiteBanner", {
          dismissed,
        })}
      >
        {ctaUrl ? (
          <a className="-cta" href={ctaUrl}>
            <ContentfulPicture
              className="-logo"
              alt="logo"
              images={[
                {
                  src: image.fields.file.url,
                  device: "desktop",
                },
              ]}
            />
            <span className="-title">{title}</span>
            <span className="-label">
              {ctaLabel}
              <span className="-arrow"> →</span>
            </span>
          </a>
        ) : (
          <div className="-cta">
            <ContentfulPicture
              className="-logo"
              alt="logo"
              images={[
                {
                  src: image.fields.file.url,
                  device: "desktop",
                },
              ]}
            />
            <span className="-title">{title}</span>
          </div>
        )}
        <span
          onClick={this.handleDismiss}
          className={classnames("-dismiss", {
            enabled: dismissable,
          })}
        >
          ✕
        </span>
      </div>
    );
  }
}

export default HeatwaveSiteBanner;

import Route from "route-parser";

export const pathWithoutTrailingSlash: (arg0: string) => string = (
  pathname,
) => {
  if (pathname.length > 1 && pathname.slice(-1) === "/") {
    return pathname.slice(0, -1);
  }

  return pathname;
};
export const routeMatches: (arg0: string) => any = (spec: string) => {
  const pathname = pathWithoutTrailingSlash(window.location.pathname);
  // @ts-expect-error ts-migrate(2348) FIXME: Value of type 'typeof Route' is not callable. Did ... Remove this comment to see the full error message
  return Route(spec).match(pathname);
};
let routed = false;

const route: (arg0: string, arg1: (arg0: any) => any) => void = (spec, cb) => {
  if (routed) return;
  const result = routeMatches(spec);

  if (result) {
    routed = true;
    cb(result);
  }
};

export default route;

/**
 * @namespace Money
 */
import { toNumber, isNaN } from "lodash/fp";

/**
 * @name Currency
 * @memberof Money
 * @description This is the list of all of the currencies that our system supports.
 */
export type Currency = "USD";

/**
 * @name Money
 * @memberof Money
 * @description Represents money! The type is generic in the currency so that we can rely on the type system to prevent us from doing things--like math--across different currencies.
 */
export type Money<C extends Currency> = {
  amount: number;
  currency: C;
};

/**
 * @name money
 * @memberof Money
 * @description Constructs a value of the Money type. See below for currency-specific constructors.
 */
export const money =
  <C extends Currency>(
    currency: C,
  ): ((m: string | number | Record<string, string | number>) => Money<C>) =>
  (m) => {
    if (m === null || m === undefined)
      throw new Error("Cannot create money from nothing.");

    switch (typeof m) {
      case "string": {
        const n = toNumber(m);
        if (isNaN(n)) throw new Error(`Cannot convert ${m} to number.`);
        return money(currency)(n);
      }

      case "object": {
        if (!m.currency) throw new Error("Currency is missing from object.");

        if (typeof m.currency !== "string")
          throw new Error("Cannot create money without a currency.");

        if (m.currency !== currency)
          throw new Error(`Cannot create ${currency} from ${m.currency}.`);

        return money(currency)(m.amount);
      }

      case "number":
        return {
          amount: m,
          currency,
        };

      default:
        throw new Error(`Cannot create money from value of type ${typeof m}.`);
    }
  };

/**
 * @name usd
 * @memberof Money
 * @description Constructors a value of USD money, specifically.
 */
export const usd = money<"USD">("USD");

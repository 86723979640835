import useSWR from "swr/immutable";
import { SWRConfiguration, unstable_serialize } from "swr";

import {
  Completed,
  Loading,
  Failed,
  loading,
  failed,
} from "storefront/lib/Resource";

type UseDataOptions<TData, TArgs> = {
  args?: TArgs;
  shouldFetch?: boolean | (() => boolean);
  key?: string;
  preloadedData?: TData;
};

export const generateDataKey = (...args: any): string =>
  unstable_serialize(args);

export function completed<TGetter extends (...args: any) => any>(
  data: Awaited<ReturnType<TGetter>>,
  mutatorFn: Mutator<Awaited<ReturnType<TGetter>>>,
  mutate: () => void,
) {
  return {
    type: "Completed" as const,
    value: data,
    mutate: mutatorFn,
    revalidate: () => mutate(),
  };
}

type Mutator<TData> = (
  data?: TData,
  opts?: {
    revalidate?: boolean;
  },
) => Promise<TData | undefined>;

export type DataResource<TData> =
  | Loading
  | Failed
  | (Completed<TData> & {
      mutate: Mutator<TData>;
      revalidate: () => void;
    });

function useData<TGetter extends (...args: any) => any>(
  getter: TGetter,
  options?: UseDataOptions<Awaited<ReturnType<TGetter>>, Parameters<TGetter>>,
): DataResource<Awaited<ReturnType<TGetter>>> {
  const {
    shouldFetch = true,
    key: keyOverride,
    preloadedData,
    args,
  } = options || {};

  const key = keyOverride || generateDataKey(getter, args);

  const shouldFetchData =
    typeof shouldFetch === "function" ? shouldFetch() : shouldFetch;

  const swrOptions: SWRConfiguration = {};

  if (preloadedData) {
    swrOptions.fallbackData = preloadedData;
  }

  // Fetch data using useSWR
  const { data, isValidating, error, mutate } = useSWR<
    Awaited<ReturnType<TGetter>>
  >(
    shouldFetchData ? key : null,
    // @ts-ignore ts(2488) - TS doesn't like the spread operator here, will try to fix later
    () => (args ? getter(...args) : getter()),
    swrOptions,
  );

  // Handle error state
  if (error) {
    return failed(error);
  }

  // Handle loading state
  if (isValidating || typeof data === "undefined") {
    return loading;
  }

  const mutatorFn: Mutator<Awaited<ReturnType<TGetter>>> = async (
    newData,
    opts,
  ) => {
    if (typeof newData === "undefined") {
      return mutate();
    }
    await mutate(newData, { revalidate: opts?.revalidate });
    return newData;
  };

  return completed(data, mutatorFn, mutate);
}

export default useData;

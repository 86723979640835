import React from "react";
import { prop } from "lodash/fp";
import ContentfulConfigs from "storefront/Contentful/Configs";
import withContentful, {
  WithContentfulProps,
} from "storefront/Contentful/withContentful";
import { SiteBannerEntry, SiteBannerModuleEntry } from "./types";
import HeatwaveSiteBanner from "./HeatwaveSiteBanner";

/**
 * @name GlobalHeader.SiteBanner.renderBanner
 * @description Renders a site-wide banner based on banner type
 * @param {SiteBannerEntry} entry
 * @returns {React$Element<*>}
 */
const renderBanner = (entry: SiteBannerEntry) => {
  switch (entry.contentType) {
    case "heatwaveSiteBanner":
      return <HeatwaveSiteBanner entry={entry} />;

    default:
      return null;
  }
};

/**
 * @name GlobalHeader.SiteBanner
 * @description A global site-wide banner
 * @param {Props} props
 * @returns {React$Element}
 */
const SiteBanner = (props: WithContentfulProps<SiteBannerModuleEntry>) => {
  if (
    props.entry &&
    prop("entry.contentType", props) === "moduleSiteBanner" &&
    props.entry.fields.enabled
  ) {
    return (
      <div className="SiteBanner">
        {renderBanner(props.entry.fields.bannerContent)}
      </div>
    );
  }

  if (props.error) {
    // return <div>{props.error.message}</div>;
    return <></>;
  }

  return <div />;
};

const ContentfulBanner = withContentful<SiteBannerModuleEntry>(
  "moduleSiteBanner",
  SiteBanner,
);
const siteBannerId =
  window.PUBLIC_CONFIG.contentful.spaces.grailed.entry_ids?.sitebanner;

export default () => (
  <ContentfulBanner
    query={{
      "sys.id": `${siteBannerId}`,
    }}
    config={ContentfulConfigs}
  />
);

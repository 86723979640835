import { camelize } from "camelscore";
import { map, prop, compose, compact } from "lodash/fp";
import type { Id } from "storefront/lib/Id";
import "storefront/lib/Id";
import type { ListingIndexType } from "storefront/Algolia/IndexType";
import createInsecureAlgoliaClient from "storefront/Algolia/Client/createInsecureClient";
import { getAlgoliaIndexName } from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import getConfig from "storefront/GrailedAPI/v1/Config/getConfig";
import type { Listing } from "storefront/Listing";

/**
 * @name fetchByIds
 * @description Returns an array of Listings using the Listing Index
 * NOTE: [@niculistana] We recommend using useAlgoliaFetch hook if possible.
 * This uses algoliaClientAsync which will use public search key from api/config
 * which will be deprecated.
 *
 * @param {Array<string>} ids - an array of ids, note that they are STRINGS.
 * @return {Promise<Array<Listing>>}
 * @see useAlgoliaFetch
 */
const fetchByIds = (
  ids: Array<Id>,
  indexKey: ListingIndexType | null | undefined,
): Promise<Array<Listing>> => {
  const objectIdStrings = map(String, ids);
  return Promise.all([createInsecureAlgoliaClient(), getConfig()]).then(
    ([client, config]) => {
      const indexName = getAlgoliaIndexName(
        "listings",
        indexKey || "default",
      )(config);
      const index = client.initIndex(indexName);
      return index
        .getObjects(objectIdStrings)
        .then(compose([camelize, compact, prop("results")]));
    },
  );
};

export default fetchByIds;

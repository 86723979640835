import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash/fp";
import { pageTypeFromMap } from "storefront/Analytics/EventCreators/productAddedToWishlist";
import type { Id } from "storefront/lib/Id";
import type { PageType } from "storefront/Analytics/Event";
import useAnalytics from "../../../hooks/useAnalytics";
import moduleClicked from "../../../Analytics/EventCreators/moduleClicked";
import type { Listing } from "../../../Listing";
import type {
  ListingsFromFeedModule,
  TrackingItemProperties,
} from "../../../Contentful/types";
import getListingsFromUuid from "../../../GrailedAPI/v1/Listings/getListingsFromUuid";
import Heading from "../Heading";
import ListingCarousel from "../../ListingCarousel";
import ListingCarouselWrapper from "../../ListingCarousel/Wrapper";

type Props = {
  entry: ListingsFromFeedModule;
  position: number;
  from: string;
  pageType: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

const ListingsFromFeed = ({
  entry,
  position,
  from,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: Props) => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [listings, setListings] = useState<Array<Listing>>([]);
  const { track } = useAnalytics();
  const moduleType = "Listings From Feed";
  useEffect(() => {
    const { feedId } = entry.fields;
    getListingsFromUuid(feedId, 18).then((res) => {
      setIsFetching(false);
      setListings(res.data);
    });
  }, [entry]);

  const trackClick = (properties: TrackingItemProperties) => (): void => {
    const { name, title } = entry.fields;
    track(
      moduleClicked({
        ...properties,
        from,
        moduleType,
        moduleName: title,
        moduleNameContentful: name,
        modulePosition: position,
      }),
    );
  };

  const { title, ctaText, feedId } = entry.fields;
  const ctaTracking = {
    itemType: "CTA",
    itemPosition: "CTA",
    itemName: ctaText,
    itemNameContentful: ctaText,
    imageUrl: "",
  };
  if (!isFetching && isEmpty(listings)) return null;
  const productAddedToWishlistFrom = pageTypeFromMap[pageType] || pageType;
  return (
    <ListingCarouselWrapper className="Module--ListingsFromFeed">
      <Heading
        title={title}
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ itemType: string; itemPosition... Remove this comment to see the full error message
        trackClick={trackClick(ctaTracking)}
        ctaLink={`/shop/${feedId}`}
        ctaText={ctaText}
      />
      {isFetching ? (
        <div className="-loading" />
      ) : (
        <ListingCarousel
          listings={listings}
          trackClick={trackClick}
          numOfItemsToShow={6}
          moduleName={title}
          moduleType={moduleType}
          pageType={pageType}
          pageTypeIdentifier={pageTypeIdentifier}
          pageTypeName={pageTypeName}
          from={productAddedToWishlistFrom}
        />
      )}
    </ListingCarouselWrapper>
  );
};

export default ListingsFromFeed;

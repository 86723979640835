import type { Conversation } from "storefront/Conversation";

export const ACTIVITY_ADDED: "Conversation.ActivityAdded" =
  "Conversation.ActivityAdded";
export type ActivityAdded = {
  type: typeof ACTIVITY_ADDED;
  payload: {
    conversation: Conversation;
  };
};
type Response = {
  data: {
    conversation: Conversation;
  };
};
export const activityAdded = (response: Response): ActivityAdded => ({
  type: ACTIVITY_ADDED,
  payload: {
    conversation: response.data.conversation,
  },
});

import React from "react";
import truncate from "truncate";
import classnames from "classnames";
import { Listing } from "storefront/Listing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaMyItemsListing } from "storefront/Listing/AlgoliaMyItemsListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { User } from "storefront/User";
import styles from "./ListingMetadata.module.scss";

type Props = {
  listing:
    | Listing
    | AlgoliaMyItemsListing
    | GrailedAPILightListing
    | AlgoliaListing;
  currentUser?: User | null;
  classNames?: {
    metadataClassName?: string;
    designerClassName?: string;
    titleClassName?: string;
  };
};

const StatusLabels = ({ listing }: Pick<Props, "listing">) => (
  <span>
    {"deleted" in listing && listing.deleted ? (
      <span className="red bold">DELETED</span>
    ) : null}

    {"hidden" in listing && listing.hidden ? (
      <span className="blue bold">HIDDEN</span>
    ) : null}
  </span>
);

const ListingMetadata = ({ listing, currentUser, classNames }: Props) => (
  <div className={classnames(styles.metadata, classNames?.metadataClassName)}>
    <div className={styles.designerAndSize}>
      <p className={classnames(styles.designer, classNames?.designerClassName)}>
        {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 1. */}
        {truncate(listing.designerNames || listing.designer.name)}
      </p>
      <p className={styles.size}>
        {listing.size === "one size" ? "os" : listing.size}
      </p>
    </div>

    <div>
      {currentUser && currentUser.isAdmin ? (
        <StatusLabels listing={listing} />
      ) : null}
      <p
        data-cy="listing-title"
        className={classnames(styles.title, classNames?.titleClassName)}
      >
        {listing.title}
      </p>
    </div>
  </div>
);

export default ListingMetadata;

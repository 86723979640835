import React from "react";
import cn from "classnames";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import {
  FeedGridModule,
  TrackingItemProperties,
  ItemType,
} from "storefront/Contentful/types";
import Heading from "../Heading";
import FeedItem from "./FeedItem";

import styles from "./FeedGrid.module.scss";

type Props = {
  entry: FeedGridModule;
  from: string;
  position: number;
};

const FeedGrid = ({ entry, from, position }: Props) => {
  const { track } = useAnalytics();

  const trackClick =
    (properties: TrackingItemProperties) =>
    (_event: React.MouseEvent<HTMLAnchorElement>) => {
      const { name, title } = entry.fields;
      track(
        moduleClicked({
          ...properties,
          from,
          moduleType: "Feed Grid",
          moduleName: title,
          moduleNameContentful: name,
          modulePosition: position,
        }),
      );
    };

  const { title, ctaText, ctaLink, feedItems } = entry.fields;
  const itemTypeAndPosition: ItemType = "CTA";
  const ctaTracking = {
    itemType: itemTypeAndPosition,
    itemPosition: itemTypeAndPosition,
    itemName: ctaText,
    itemNameContentful: ctaText,
    imageUrl: "",
  };

  return (
    <div className={styles.feedGrid}>
      <Heading
        title={title}
        ctaText={ctaText}
        ctaLink={ctaLink}
        trackClick={trackClick(ctaTracking)}
      />

      <div
        className={cn(styles.items, {
          [styles.four]: feedItems.length === 4,
          [styles.six]: feedItems.length === 6,
        })}
      >
        {feedItems.map((item, index) => {
          const href = item.fields.href || `/shop/${item.fields.feedId}`;

          return (
            <FeedItem
              entry={item}
              href={href}
              index={index}
              key={item.sys.id}
              trackClick={trackClick}
              className={styles.item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FeedGrid;

import type { Dispatch } from "redux";
import type { Article } from "storefront/Article";
import timeout from "storefront/lib/timeout";
import {
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAILURE,
  CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAILURE,
  UPDATE_ARTICLE_REQUEST,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  NEW_ARTICLE,
  EDIT_ARTICLE,
  REMOVE_ARTICLE,
  VIEW_ARTICLES_LIST,
  CHANGE_ARTICLE_TAGS,
  SET_ARTICLE_HOMEPAGE_PREVIEW_REQUEST,
  SET_ARTICLE_HOMEPAGE_PREVIEW_SUCCESS,
  SET_ARTICLE_HOMEPAGE_PREVIEW_FAILURE,
} from "../constants/action_types";
import GrailedAPI from "../lib/grailed_api";

export function fetchArticlesRequest() {
  return {
    type: FETCH_ARTICLES_REQUEST,
    payload: {},
  };
}
export function fetchArticlesError(error: Error) {
  return {
    type: FETCH_ARTICLES_FAILURE,
    payload: {
      error,
    },
  };
}
export function fetchArticlesSuccess(
  articles: Article[],
  isNewSearch: boolean,
) {
  return {
    type: FETCH_ARTICLES_SUCCESS,
    payload: {
      articles,
      isNewSearch,
    },
  };
}
export function fetchArticles(title?: string) {
  const isNewSearch = title !== undefined;
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchArticlesRequest());
    timeout(GrailedAPI.articles.all(title))
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      .then((response) => dispatch(fetchArticlesSuccess(response, isNewSearch)))
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(fetchArticlesError(error));
        }

        throw error;
      });
  };
}
export function fetchMoreArticles(url: string) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(fetchArticlesRequest());
    timeout(GrailedAPI.articles.fetchPage(url))
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      .then((data) => dispatch(fetchArticlesSuccess(data)))
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(fetchArticlesError(error));
        }

        throw error;
      });
  };
}
export function createArticleRequest(article: Article): Record<string, any> {
  return {
    type: CREATE_ARTICLE_REQUEST,
    payload: {
      article,
    },
  };
}
export function createArticleError(error: Error): Record<string, any> {
  return {
    type: CREATE_ARTICLE_FAILURE,
    payload: {
      error,
    },
  };
}
export function createArticleSuccess(article: Article): Record<string, any> {
  return {
    type: CREATE_ARTICLE_SUCCESS,
    payload: {
      article,
    },
  };
}
export function createOrUpdateArticle(
  article: Article,
  publicationNotification: string | null | undefined,
) {
  return (dispatch: (...args: Array<any>) => any) => {
    if (article.id) {
      dispatch(updateArticle(article.id, article, publicationNotification));
    } else {
      dispatch(createArticle(article, publicationNotification));
    }
  };
}
export function createArticle(
  article: Article,
  publicationNotification: string | null | undefined,
) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(createArticleRequest(article));
    GrailedAPI.articles
      .create(article, publicationNotification)
      .then(() => {
        window.location.href = "/internal/articles";
      })
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(createArticleError(error));
        }

        return dispatch(createArticleError(error));
      });
  };
}
export function updateArticleRequest(article: Article): Record<string, any> {
  return {
    type: UPDATE_ARTICLE_REQUEST,
    payload: {
      article,
    },
  };
}
export function updateArticleError(error: Error): Record<string, any> {
  return {
    type: UPDATE_ARTICLE_FAILURE,
    payload: {
      error,
    },
  };
}
export function updateArticleSuccess(article: Article): Record<string, any> {
  return {
    type: UPDATE_ARTICLE_SUCCESS,
    payload: {
      article,
    },
  };
}
type Id = string | number;
export function updateArticle(
  id: Id,
  article: Article,
  publicationNotification: string | null | undefined,
) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(updateArticleRequest(article));
    timeout(GrailedAPI.articles.update(id, article, publicationNotification))
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      .then((response) => dispatch(updateArticleSuccess(response.data)))
      .then(() => {
        window.location.href = "/internal/articles";
      })
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(updateArticleError(error));
        }

        return dispatch(updateArticleError(error));
      });
  };
}
export function deleteArticle(article: Article) {
  return (dispatch: (...args: Array<any>) => any) => {
    GrailedAPI.articles
      .delete(article.id)
      .then(() => dispatch(removeArticle(article)));
  };
}
export function newArticle(): Record<string, any> {
  return {
    type: NEW_ARTICLE,
    payload: {
      article: {},
    },
  };
}
export function editArticle(article: Article): Record<string, any> {
  return {
    type: EDIT_ARTICLE,
    payload: {
      article,
    },
  };
}
export function removeArticle(article: Article): Record<string, any> {
  return {
    type: REMOVE_ARTICLE,
    payload: {
      article,
    },
  };
}
export function viewArticlesList(): Record<string, any> {
  return {
    type: VIEW_ARTICLES_LIST,
    payload: {},
  };
}
export function changeArticleTags(tags: string[]): Record<string, any> {
  return {
    type: CHANGE_ARTICLE_TAGS,
    payload: {
      tags,
    },
  };
}
export function setHomepageArticlePreview(id: number, position: number) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch({
      type: SET_ARTICLE_HOMEPAGE_PREVIEW_REQUEST,
      payload: {},
    });
    GrailedAPI.articles
      .setHomepagePreview(id, position)
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) =>
        dispatch({
          type: SET_ARTICLE_HOMEPAGE_PREVIEW_SUCCESS,
          payload: response,
        }),
      )
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
      .catch((error) =>
        dispatch({
          type: SET_ARTICLE_HOMEPAGE_PREVIEW_FAILURE,
          payload: error,
        }),
      );
  };
}

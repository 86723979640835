import type { Id } from "storefront/lib/Id";
import { get } from "storefront/GrailedAPI/request";
import type { Listing } from "storefront/Listing";

const getRecommendedSimilarListings = (userId: Id): Promise<Array<Listing>> =>
  get(`/api/users/${userId}/recommendations/similar_listings`).then(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    (response) => response.data,
  );

export default getRecommendedSimilarListings;

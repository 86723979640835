import React from "react";
import { useSelector } from "react-redux";
import { Id } from "storefront/lib/Id";
import {
  GlobalState,
  fittingRoom as fittingRoomSelector,
} from "storefront/GlobalState";
import { Authentication } from "storefront/Authentication";
import { FollowedListings } from "storefront/User";
import { From } from "storefront/Analytics/EventCreators/productAddedToWishlist";
import Price from "storefront/components/Price";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { LegacyListing } from "storefront/Listing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import SaveListing from "storefront/components/SaveListing/Button";
import MuteNotifications from "./MuteNotifications";
import HeartButton from "./HeartButton";
import styles from "./ListingPriceAndHeart.module.scss";

const followedListingsSelector = (state: GlobalState): FollowedListings =>
  state.session.currentUser.followedListings || [];

const fittingRoomUserIdSelector = (state: GlobalState): Id | null | undefined =>
  fittingRoomSelector(state)?.fittingRoomUserId;

const userIsViewingOwnFittingRoom = (
  authentication: Authentication,
  fittingRoomUserId: Id | null | undefined,
): boolean => {
  if (authentication.type !== "Authenticated") return false;
  return authentication.user.id === fittingRoomUserId;
};

type Props = {
  from: From | undefined;
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing;
  auth: Authentication;
  isSimilarListingsItem?: boolean;
  isViewingOwnWardrobe?: boolean;
};

const ListingPriceAndHeart = ({
  from,
  listing,
  auth,
  isSimilarListingsItem,
  isViewingOwnWardrobe = false,
}: Props) => {
  const isCpdGtm1FavoritesEnabledResource =
    useFeatureFlag("cpd_gtm1_favorites");

  const followedListings = useSelector<GlobalState, FollowedListings>(
    followedListingsSelector,
  );

  const fittingRoomUserId = useSelector<GlobalState, Id | null | undefined>(
    fittingRoomUserIdSelector,
  );

  const isFollowed = followedListings.includes(listing.id);

  const showMuteButton =
    from === "favorites" &&
    isFollowed &&
    (userIsViewingOwnFittingRoom(auth, fittingRoomUserId) ||
      isViewingOwnWardrobe);

  const isCpdGtm1FavoritesEnabled =
    isCpdGtm1FavoritesEnabledResource.type === "Completed" &&
    isCpdGtm1FavoritesEnabledResource.value;

  const showSaveButton =
    isCpdGtm1FavoritesEnabled &&
    from === "saved_listings" &&
    "inSavedList" in listing &&
    listing.inSavedList;

  if (isCpdGtm1FavoritesEnabled) {
    return (
      <div className={styles.listingPriceAndHeart}>
        <Price size="small" listing={listing} className={styles.listingPrice} />

        {showMuteButton ? (
          <MuteNotifications listing={listing} useNewIcon />
        ) : null}

        {showSaveButton ? (
          <SaveListing className={styles.saveListing} listing={listing} />
        ) : null}

        <HeartButton
          className={styles.favoriteListing}
          listing={listing}
          from={from}
          isSimilarListingsItem={isSimilarListingsItem}
        />
      </div>
    );
  }

  return (
    <div className={styles.listingPriceAndHeart}>
      <Price size="small" listing={listing} className={styles.listingPrice} />

      {showMuteButton ? <MuteNotifications listing={listing} /> : null}

      <HeartButton
        listing={listing}
        from={from}
        isSimilarListingsItem={isSimilarListingsItem}
      />
    </div>
  );
};

export default ListingPriceAndHeart;

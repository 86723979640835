import React, { useEffect, ComponentType } from "react";
import { connect } from "react-redux";
import { prop } from "lodash/fp";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import { WithContentfulProps } from "storefront/Contentful/withContentful";
import { getId } from "../../../Contentful/Entry";
import { PageEntry } from "../../../Contentful/types";
import CategoryGrid from "../CategoryGrid";
import AlertBanner from "../../SiteBanner/AlertBanner";
import DCOBlock from "../DCOBlock";
import DCOEntryPoint from "../DCOEntryPoint";
import FeaturedContentModule from "../FeaturedContentModule";
import FeaturedCapsules from "../FeaturedCapsules";
import DesignerGrid from "../DesignerGrid";
import FeedGrid from "../FeedGrid";
import FullWidthCTA from "../FullWidthCTA";
import FeedCarousel from "../FeedCarousel";
import VisualFilters from "../VisualFilters";
import ListingsFromFeed from "../ListingsFromFeed";
import RecentlyViewed from "../RecentlyViewed";
import DesignersForYou from "../DesignersForYou";
import DiscoverDailyCarousel from "../DiscoverDailyCarousel";
import GuideScreen from "../Guide/GuideScreen";
import ContentfulErrorBoundary from "../ContentfulModuleWrapper/ContentfulErrorBoundary";
import BigVideoModule from "../BigVideoModule";
import * as actions from "./Actions";

// This is a mapping from Contentful Content Type ID to component that will render it
const moduleComponent = {
  rotatingFeaturedBanner: FeaturedContentModule,
  guideScreen: GuideScreen,
  moduleAlertBanner: AlertBanner,
  moduleCategoryGrid: CategoryGrid,
  moduleCollections: FeaturedCapsules,
  moduleDcoBlock: DCOBlock,
  moduleDesignerGrid: DesignerGrid,
  moduleDiscoverDailyCarousel: DiscoverDailyCarousel,
  moduleFeedCarousel: FeedCarousel,
  moduleFeedGrid: FeedGrid,
  moduleFullWidthCta: FullWidthCTA,
  moduleListingsFromFeed: ListingsFromFeed,
  moduleRecentlyViewed: RecentlyViewed,
  moduleVisualFilters: VisualFilters,
  moduleDesignerCarousel: DesignersForYou,
  searchCta: BigVideoModule,
  // Reusing the content type originally called `tester` in Contentful bc we've run out of content types
  tester: DCOEntryPoint,
};

const renderModule =
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'from' implicitly has an 'any' type.
  (from, pageType, pageTypeIdentifier, pageTypeName) => (module, index) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const Module = moduleComponent[module.contentType];
    if (!Module) return null;
    return (
      <ContentfulErrorBoundary key={getId(module)}>
        <Module
          key={getId(module)}
          position={index}
          from={from}
          entry={module}
          pageType={pageType}
          pageTypeIdentifier={pageTypeIdentifier}
          pageTypeName={pageTypeName}
        />
      </ContentfulErrorBoundary>
    );
  };

type OwnProps = {
  baseClassName: string;
  from: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
} & WithContentfulProps<PageEntry>;

type DispatchProps = {
  contentfulResponded: (page: PageEntry) => void;
};

type Props = DispatchProps & OwnProps;

/**
 * @name ContentfulPageWrapper
 * @description Renders a generic Contentful Modules Wrapper
 * that can be used on different pages
 * @param {Props} props
 * @param {?PageEntry} props.entry - a contentful entry that has the added contentType
 * @param {?ContentfulError} props.error - a contentful error
 * @returns {React$Element}
 */
const ContentfulPageWrapper = ({
  entry,
  error,
  baseClassName,
  from,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
  contentfulResponded,
}: Props) => {
  // TODO: @hsiung check to see if this is even needed. Doesnt seem like we need to store modules in redux
  // if we do need this it should be moved to the withContentful HOC
  useEffect(() => {
    if (entry) contentfulResponded(entry);
  }, [entry, contentfulResponded]);

  if (entry) {
    const modules = prop("fields.modules", entry) || [];
    return (
      <div className={`${baseClassName}--Modules`}>
        {modules.map(
          renderModule(from, pageType, pageTypeIdentifier, pageTypeName),
        )}
      </div>
    );
  }

  if (error) return <></>;

  return (
    <>
      <div className={`${baseClassName}--Modules`}>
        <div className="Module--FeaturedBlock _loading" />
      </div>
      <div className="Homepage--Modules-Loading">
        <h3 className="-title">...</h3>
        <div className="-container">
          <div className="-item _loading" />
          <div className="-item _loading" />
          <div className="-item _loading" />
          <div className="-item _loading" />
          <div className="-item _loading" />
          <div className="-item _loading" />
        </div>
      </div>
    </>
  );
};

const ConnectedContentfulPageWrapper: ComponentType<OwnProps> = connect(
  null,
  actions,
)(ContentfulPageWrapper);

export default ConnectedContentfulPageWrapper;

import React, { Component, Suspense, lazy } from "react";
import classNames from "classnames";
import { pipe, get } from "lodash/fp";
import {
  setAlgoliaIndex,
  setAlgoliaQueryId,
  setAlgoliaAbTestId,
  setAlgoliaAbTestVariantId,
} from "storefront/GrailedSearchParams";
import listingPath from "storefront/paths/listing";
import {
  withAuthentication,
  AuthenticationProps,
} from "storefront/hooks/useAuthentication";
import { Authentication } from "storefront/Authentication";
import extract, { isAdmin, isCurator } from "storefront/Authentication/extract";
import {
  withAnalytics,
  WithAnalyticsProps,
} from "storefront/hooks/useAnalytics";
import { ProductClickedFrom } from "storefront/Analytics/Event";
import { From } from "storefront/Analytics/EventCreators/productAddedToWishlist";
import trackProductClickedFrom from "storefront/Analytics/trackProductClickedFrom";
import withMediaQuery, {
  MediaQueryHOCProps,
} from "storefront/hocs/withMediaQuery";
import { MOBILE } from "storefront/hooks/useMediaQuery";
import {
  withPublicConfig,
  PublicConfigProps,
} from "storefront/hooks/usePublicConfig";
import authWrapper, {
  AuthWrapperProps,
} from "storefront/components/Authentication/authWrapper";
import { UserSelf } from "storefront/User";
import { LegacyListing } from "storefront/Listing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import {
  AlgoliaInstantSearchListing,
  isAlgoliaInstantSearchListing,
} from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { GrailedAPIPhoto } from "storefront/Photo";
import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import { isKnownBot } from "storefront/Analytics/isKnownBot";
import { Transaction } from "storefront/Transaction";
import SmallTitle from "storefront/components/SmallTitle";
import statusClassName from "storefront/components/TransactionListingCard/statusClassName";
import ListingAge from "./ListingAge";
import ListingItemBadge from "./ListingItemBadge";
import ListingMetadata from "./ListingMetadata";
import ListingPriceAndHeart from "./ListingPriceAndHeart";
import RotateButtons from "./RotateButtons";
import SeeSimilarLink from "./SeeSimilarLink";
import Image from "../ImageV2";
import "./styles.scss";

const AdminFeedItemButtons = lazy(
  () =>
    import(
      /* webpackChunkName: "AdminFeedItemButtons" */
      "../InternalTools/Listing/AdminPanel/FeedItemButtons"
    ),
);

const SwipeCoverPhotoButtons = lazy(
  () =>
    import(
      /* webpackChunkName: "SwipeCoverPhotoButtons" */
      "./SwipeCoverPhotoButtons"
    ),
);

const maybeGetUser = extract<UserSelf | null>(
  (user) => user,
  () => null,
);

type AdminActionButtonsProps = {
  authentication: Authentication;
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing;
  hideListing: () => void;
};

const AdminActionButtons = ({
  authentication,
  listing,
  hideListing,
}: AdminActionButtonsProps) => (
  <Suspense fallback={null}>
    <AdminFeedItemButtons
      currentUser={maybeGetUser(authentication)}
      listing={listing}
      hideListing={hideListing}
    />
  </Suspense>
);

// Determines whether or not we style the item as a carousel item or a feed item
export type DisplayStyle = "carousel" | "feed";

export type OwnProps = {
  className?: string;
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing;
  height?: number;
  muted?: boolean;
  from?: From;
  displayStyle: DisplayStyle;
  productClickedFrom?: ProductClickedFrom;
  path?: string;
  hasTarget?: boolean;
  scrollContainerQueryString?: string;
  additionalTrack?: () => void;
  algoliaIndex?: string;
  algoliaAbTestId?: string | null;
  algoliaAbTestVariantId?: string | null;
  isSimilarListingsItem?: boolean;
  transaction?: Transaction;
  isViewingOwnWardrobe?: boolean;
};

type OwnPropsWithAuthWrapper = AuthWrapperProps & OwnProps;

type OwnPropsWithAuthWrapperAndConfig = PublicConfigProps &
  OwnPropsWithAuthWrapper;

type OwnPropsWithAuthWrapperAndConfigAndAnalytics = WithAnalyticsProps &
  OwnPropsWithAuthWrapperAndConfig;

type OwnPropsWithAuthWrapperAndConfigAndAnalyticsAndMediaQuery =
  MediaQueryHOCProps & OwnPropsWithAuthWrapperAndConfigAndAnalytics;

type Props = AuthenticationProps &
  OwnPropsWithAuthWrapperAndConfigAndAnalyticsAndMediaQuery;

type State = {
  isHidden: boolean;
  coverPhoto: GrailedAPIPhoto;
};

/**
 * @name Feed.ListingItem
 * @description Renders the familiar rectangular listing you see on the main feed
 */
export class ListingItem extends Component<Props, State> {
  state: State = {
    isHidden: false,
    coverPhoto: this.props.listing.coverPhoto,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // if authentication state changes, do update
    if (this.props.authentication !== nextProps.authentication) {
      return true;
    }

    // if screensize changes, do update
    if (this.props.doesMediaQueryMatch !== nextProps.doesMediaQueryMatch) {
      return true;
    }

    if (
      this.state.isHidden !== nextState.isHidden ||
      this.state.coverPhoto.url !== nextState.coverPhoto.url ||
      this.state.coverPhoto.id !== nextState.coverPhoto.id ||
      this.state.coverPhoto.rotate !== nextState.coverPhoto.rotate ||
      this.props.muted !== nextProps.muted
    ) {
      return true;
    }

    return true;
  }

  hideListing = () => {
    this.setState({
      isHidden: true,
    });
  };

  reloadPhoto = (coverPhoto: GrailedAPIPhoto) => {
    this.setState({
      coverPhoto,
    });
  };

  createAltText: () => string = () => {
    const { title, designers } = this.props.listing;
    const designerNames = designers.map((d) => d.name).join(" ");
    return `${designerNames} ${title}`;
  };

  canShowCuratorButtons() {
    const { doesMediaQueryMatch, authentication } = this.props;
    const isMobile = doesMediaQueryMatch === true;
    const userIsAdminOrCurator =
      isAdmin(authentication) || isCurator(authentication);
    return !isMobile && userIsAdminOrCurator;
  }

  renderCoverPhoto: () => React.ReactElement<
    React.ComponentProps<"img">,
    "img"
  > = () => {
    const { coverPhoto } = this.state;
    return (
      <Image
        height={320}
        width={240}
        rotate={coverPhoto.rotate}
        src={coverPhoto.url}
        fit="crop"
        alt={this.createAltText()}
      />
    );
  };

  renderSwipeCoverPhoto() {
    const { listing, authentication } = this.props;

    // only show these to curators; other groups don't have enough real estate
    if (isCurator(authentication)) {
      return (
        <Suspense fallback={<></>}>
          <SwipeCoverPhotoButtons
            listing={listing}
            onSuccess={this.reloadPhoto}
            onError={(err) => {
              window.console.log(err);
              return null;
            }}
          />
        </Suspense>
      );
    }

    return null;
  }

  renderRotateButtons() {
    const { coverPhoto } = this.state;
    return (
      <Suspense fallback={<></>}>
        <RotateButtons
          photoId={coverPhoto.id}
          onSuccess={this.reloadPhoto}
          onError={(err) => {
            window.console.log(err);
            return null;
          }}
        />
      </Suspense>
    );
  }

  renderSellerTransactionCount() {
    const totalBoughtAndSold = get(
      "listing.user.totalBoughtAndSold",
      this.props,
    );

    if (!this.canShowCuratorButtons() || totalBoughtAndSold == null) {
      return null;
    }

    let color;

    if (totalBoughtAndSold === 0) {
      color = "red";
    } else if (totalBoughtAndSold && totalBoughtAndSold < 5) {
      color = "orange";
    }

    return (
      <span className={classNames("user-transaction-count-bubble", color)}>
        {totalBoughtAndSold || 0}
      </span>
    );
  }

  render() {
    const {
      listing,
      additionalTrack,
      productClickedFrom,
      from,
      displayStyle,
      hasTarget,
      authentication,
      algoliaIndex,
      algoliaAbTestId,
      algoliaAbTestVariantId,
      tracker,
      config,
      isSimilarListingsItem,
      isViewingOwnWardrobe = false,
    } = this.props;

    let path = this.props.path || listingPath(listing);

    const isBot = isKnownBot();

    /* eslint-disable no-underscore-dangle */
    if (
      !isBot &&
      isAlgoliaInstantSearchListing(listing) &&
      listing.__queryID &&
      algoliaIndex
    ) {
      const queryId = listing.__queryID;
      path = pipe(
        setAlgoliaIndex(algoliaIndex),
        setAlgoliaQueryId(queryId),
      )(path);
    }

    /* eslint-enable no-underscore-dangle */
    if (algoliaAbTestId && algoliaAbTestVariantId) {
      path = pipe(
        setAlgoliaAbTestId(algoliaAbTestId),
        setAlgoliaAbTestVariantId(algoliaAbTestVariantId),
      )(path);
    }

    const isUnavailable = listing.sold;

    const className =
      displayStyle === "carousel" ? "carousel-listing-item" : "feed-item";

    const isEligibleForSeeSimilarLink =
      displayStyle === "feed" &&
      productClickedFrom?.pageType !== "designer_category" &&
      productClickedFrom?.pageType !== "similar_listings_feed" &&
      from !== "internal";

    // TODO: reenable admin tools
    return (
      <div
        className={classNames(`${className}`, this.props.className, {
          hide: this.state.isHidden,
          closet: from === "closet",
        })}
        key={`listing-${listing.id}`}
      >
        <a
          className="listing-item-link"
          href={path}
          rel={hasTarget ? "noopener noreferrer" : undefined}
          target={hasTarget ? "_blank" : undefined}
          onClick={() =>
            trackProductClickedFrom(
              listing,
              tracker,
              config,
              from,
              productClickedFrom,
              additionalTrack,
              algoliaAbTestId,
              algoliaAbTestVariantId,
            )
          }
        >
          <div className="-overlay">
            <SuspenseWithFragment>
              <ListingItemBadge listing={listing} />
            </SuspenseWithFragment>
          </div>
          <div className={`listing-cover-photo ${isUnavailable ? "sold" : ""}`}>
            {this.renderSellerTransactionCount()}
            {this.renderCoverPhoto()}
            {this.canShowCuratorButtons() ? (
              <div className="hug-top">
                <AdminActionButtons
                  authentication={authentication}
                  listing={listing}
                  hideListing={this.hideListing}
                />
              </div>
            ) : null}
            <div className="hug-bottom">
              {this.canShowCuratorButtons()
                ? this.renderSwipeCoverPhoto()
                : null}
              {this.canShowCuratorButtons() ? this.renderRotateButtons() : null}
            </div>
          </div>

          {this.props.transaction ? (
            <SmallTitle
              className={classNames(
                "transaction-status",
                statusClassName(this.props.transaction.displayStatus.color),
              )}
            >
              {this.props.transaction.displayStatus.text}
            </SmallTitle>
          ) : (
            <ListingAge listing={listing} className="listing-age" />
          )}

          <ListingMetadata
            classNames={{ metadataClassName: "listing-metadata" }}
            listing={listing}
            currentUser={maybeGetUser(authentication)}
          />
        </a>

        <ListingPriceAndHeart
          from={from}
          listing={listing}
          auth={authentication}
          isSimilarListingsItem={isSimilarListingsItem}
          isViewingOwnWardrobe={isViewingOwnWardrobe}
        />

        {isEligibleForSeeSimilarLink && !this.props?.transaction ? (
          <SeeSimilarLink
            listing={listing}
            productClickedFrom={productClickedFrom}
          />
        ) : null}
      </div>
    );
  }
}

const ListingItemWithAuthentication =
  withAuthentication<OwnPropsWithAuthWrapperAndConfigAndAnalyticsAndMediaQuery>(
    ListingItem,
  );
const ListingItemWithAuthenticationAndMediaQuery =
  withMediaQuery<OwnPropsWithAuthWrapperAndConfigAndAnalytics>(MOBILE)(
    ListingItemWithAuthentication,
  );
const ListingItemWithAuthenticationAndMediaQueryAndAnalytics =
  withAnalytics<OwnPropsWithAuthWrapperAndConfig>(
    ListingItemWithAuthenticationAndMediaQuery,
  );
const ListingItemWithAuthenticationAndMediaQueryAndAnalyticsAndConfig =
  withPublicConfig<OwnPropsWithAuthWrapper>(
    ListingItemWithAuthenticationAndMediaQueryAndAnalytics,
  );
const AuthWrappedListingItemWithAuthenticationAndMediaQueryAndAnalyticsAndConfig =
  authWrapper<OwnProps>(
    ListingItemWithAuthenticationAndMediaQueryAndAnalyticsAndConfig,
  );
export default AuthWrappedListingItemWithAuthenticationAndMediaQueryAndAnalyticsAndConfig;
